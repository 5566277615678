import { mapState } from 'vuex';
import { apiBaseUrl } from '@/config/url';

// TODO: should re-structure this mixin and changeEditorVersion mixin
// since in v1 editor this mixin will be loaded twice,
// causing two parallel history.pushState() calls when "templateHashChanged" fired.
let _globalHistoryPushed = false;

export default {
  data() {
    return {
      historyPushed: false,
      prevTemplateHash: null,
      skip: false,
      saved: false,
      pathName: null,
    };
  },
  computed: {
    ...mapState(['templateHash']),
  },

  methods: {
    hasUnsavedChanges() {
      if (!this.prevTemplateHash) return false;
      return this.templateHash !== this.prevTemplateHash;
    },
    showUnsavedWarning(nextFunction) {
      if (this.hasUnsavedChanges()) {
        this.$modal.show('save-or-discard-changes');
      } else if (nextFunction) {
        nextFunction();
      } else if (!this.skip && this.pathName) {
        this.$router.push(this.pathName);
      } else if (!this.skip) {
        window.history.back();
      }
    },
    goBackWithSaveCheck(cb) {
      this.showUnsavedWarning(cb);
    },
    setPathName(name) {
      this.pathName = name;
    },
    discardChanges() {
      this.prevTemplateHash = null;
      this.skip = true;
      this.$modal.hide('save-or-discard-changes');
      window.history.back();
    },
    saveChanges() {
      const callback = () => {
        this.$modal.hide('save-or-discard-changes');
        window.history.back();
      };
      this.$bus.$emit('saveTemplate');
      this.$bus.$on('templateSaved', callback);
    },
    cancelChanges(editorVersionChanged) {
      window.history.pushState(null, null, null);
      if (editorVersionChanged) {
        this.$modal.hide('save-or-discard-changes-change-editor');
        return;
      }
      this.$modal.hide('save-or-discard-changes');
    },
    unloadListener(unload) {
      const { databaseId } = this.$store.state.account;
      const { campaignId } = this.$route.params;
      const unloadFB = (e) => {
        if (this.$store.state.isEditor && !this.skip && this.hasUnsavedChanges()) {
          e.preventDefault();
          e.stopPropagation();
          const message = "Your unsaved changes will be lost. Are you sure you'd like to continue?";
          e.returnValue = message;
          return message;
        }
      };
      window.addEventListener('beforeunload', unload || unloadFB);
      if (this.$route.name === 'variant_edit') {
        window.onunload = () => {
          navigator.sendBeacon(
            `${apiBaseUrl}/campaign/set-campaign-not-new`,
            new URLSearchParams(`databaseId=${databaseId}&campaignId=${campaignId}`),
          );
        };
      }
    },
    popstateListener(popstate) {
      const popstateFB = (e) => {
        if (this.skip) {
          if (!this.saved) window.history.back();
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (this.hasUnsavedChanges()) {
          this.$modal.show('save-or-discard-changes');
        }
      };

      window.addEventListener('popstate', popstate || popstateFB);
    },
    saveListener(save) {
      const saveFB = () => {
        if (this.historyPushed) {
          this.historyPushed = false;
          _globalHistoryPushed = false;
        }
      };
      this.$bus.$on('templateSaved', save || saveFB);
    },
    addListeners(unload, popstate, save) {
      this.unloadListener(unload);
      this.popstateListener(popstate);
      this.saveListener(save);
    },
  },

  created() {
    this.$bus.$on('logoClicked', this.onLogoClick);
    this.addListeners();
  },

  mounted() {
    this.$bus.$on('templateHashChanged', (value) => {
      if (value && this.templateHash && value !== this.templateHash) {
        if (!this.historyPushed && !_globalHistoryPushed) {
          this.historyPushed = true;
          _globalHistoryPushed = true;
          if (window.history && window.history.pushState) {
            window.history.pushState(null, null, null);
          }
        }
      }

      this.prevTemplateHash = value;
    });
  },

  beforeDestroy() {
    this.$bus.$off('logoClicked', this.onLogoClick);
  },
};
