import { get as _get } from 'lodash-es';

const _clone = (v) => JSON.parse(JSON.stringify(v));
const STATE_NAME = 'om_editor_history';

const parentStore = window.parent.om.store;

const frameStore = () => {
  const workspace = document.getElementById('workspaceFrame');
  return workspace.contentWindow.om.store;
};
const _emit = (...args) => window.om.bus && window.om.bus.$emit(...args);

const history = {
  pointer: -1,
  snapshots: [],
};

window[STATE_NAME] = history;

const updatePalette = (color) => {
  const parsedPalette = JSON.parse(color);
  const [getMainColor] = parsedPalette;
  window.om.store.commit('updateMainColor', getMainColor);
};

const updateThemeColors = (colors) => {
  const parsedThemeColors = JSON.parse(colors);
  const { themeColors } = parentStore.state.globalStyle.palette;
  const [difference] = parsedThemeColors.filter(
    ({ themeColor }) => !themeColors.find(({ themeColor: color }) => themeColor === color),
  );
  if (difference) {
    const getIndex = themeColors.findIndex(({ name }) => name === difference.name);

    window.om.store.commit('updateThemeColor', {
      themeColor: difference.themeColor,
      name: difference.name,
      index: getIndex,
    });
  }
};

const snapshotsEqual = (a, b) => {
  if (!a || !b) return false;
  if (a.elementsCount !== b.elementsCount) return false;
  if (a.palette !== b.palette) return false;
  if (a.elementUids !== b.elementUids) return false;
  if (a.themeColors !== b.themeColors) return false;

  console.log('Comparing templateJSON of snapshots');

  return a.templateJSON === b.templateJSON;
};

const createSnapshot = (state) => {
  return {
    selectedElement: _get(state.selectedElement, 'uid', null),
    selectedColumn: _get(state.selectedColumn, 'uid', null),
    selectedRow: _get(state.selectedRow, 'uid', null),
    selectedPage: _get(state.selectedPage, 'uid', null),

    palette: JSON.stringify(state.template.palette),
    elementsCount: state.template.elements.length,
    elementUids: JSON.stringify(state.template.elements.map(({ uid }) => uid)),
    templateJSON: JSON.stringify(state.template),
    themeColors: JSON.stringify(state.template.style.palette.themeColors),
  };
};

const loadSnapshot = (snapshot) => {
  const template = JSON.parse(snapshot.templateJSON);
  const selectedPageId = snapshot.selectedPage;
  const selectedElementId = snapshot.selectedElemen;

  _emit('replaceTemplate', { template, selectedPageId, selectedElementId });
};

const updateStoreState = () => {
  const { pointer, snapshots } = history;

  _emit('setEditorStateAttr', {
    attr: 'isActiveRedo',
    value: pointer > -1 && pointer < snapshots.length - 1,
  });
  _emit('setEditorStateAttr', { attr: 'isActiveUndo', value: pointer > 0 && snapshots.length > 1 });
};

const save = () => {
  const snapshot = createSnapshot(_clone(frameStore().state));
  const lastSnapshot = history.snapshots[history.pointer];

  if (!lastSnapshot || !snapshotsEqual(snapshot, lastSnapshot)) {
    const pointer = history.pointer + 1;

    if (pointer <= history.snapshots.length - 1) {
      history.snapshots = history.snapshots.slice(0, pointer);
    }
    history.snapshots.splice(pointer, 0, snapshot);

    history.pointer = pointer;

    updateStoreState();
  }
};

const undo = () => {
  const pointer = history.pointer - 1;
  if (pointer >= 0) {
    const snapshot = history.snapshots[pointer];

    loadSnapshot(snapshot);
    history.pointer = pointer;

    updateStoreState();
    updatePalette(snapshot.palette);
    updateThemeColors(snapshot.themeColors);

    _emit('historyChanged');
  }
};

const redo = () => {
  const pointer = history.pointer + 1;
  if (pointer <= history.snapshots.length - 1) {
    const snapshot = history.snapshots[pointer];

    loadSnapshot(snapshot);
    history.pointer = pointer;

    updateStoreState();
    updatePalette(snapshot.palette);
    updateThemeColors(snapshot.themeColors);

    _emit('historyChanged');
  }
};

const reset = () => {
  history.pointer = -1;
  history.snapshots = [];

  updateStoreState();
};

history.save = save;
history.undo = undo;
history.redo = redo;
history.reset = reset;

export default { undo, redo, save, reset };
